<template>
  <a-card class="card" :bordered="false">
    <base-page
      :queryFields="queryFields"
      :columns="columns"
      :rules="rules"
      :modelTitle="modelTitle"
      :formFields="formFields"
      :moduleName="moduleName"
      :getPageFun="getCommonPage"
      :deleteFun="deleteCommonInfo"
      :createFun="createCommonInfo"
      :updateFun="updateCommonInfo">
    </base-page>
  </a-card>
</template>
<script>
import { BasePage } from '@/components'
import { getCommonPage, createCommonInfo, updateCommonInfo, deleteCommonInfo } from '@/api/common'
export default {
  components: {
    BasePage
  },
  data() {
    return {
      queryFields: [
        {
          label: '箱型简称', name: 'abbr', fieldType: '', queryType: '%'
        }
      ],
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '箱型简称',
          dataIndex: 'abbr'
        },
        {
          title: '箱型名称',
          dataIndex: 'name'
        },
        {
          title: '皮重',
          dataIndex: 'tare_weight'
        },
        {
          title: 'TEU',
          dataIndex: 'teu'
        },
        {
          title: '显示顺序',
          dataIndex: 'order_num'
        },
        {
          title: '描述',
          dataIndex: 'description'
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      rules: {
        name: [
          { required: true, message: '请输入箱型名称', trigger: 'blur' }
        ]
      },
      modelTitle: '箱型信息',
      formFields: [
        {
          label: '箱型简称', name: 'abbr', type: 'default'
        },
        {
          label: '箱型名称', name: 'name', type: 'default'
        },
        {
          label: '皮重', name: 'tare_weight', type: 'default'
        },
        {
          label: 'TEU', name: 'teu', type: 'default'
        },
        {
          label: '显示顺序', name: 'order_num', type: 'number'
        },
        {
          label: '描述', name: 'description', type: 'textarea', span: 24
        }
      ],
      moduleName: 'box_info'
    }
  },
  methods: {
    getCommonPage,
    createCommonInfo,
    updateCommonInfo,
    deleteCommonInfo
  }
}
</script>
